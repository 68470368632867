import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';
import Section from '../components/section';
import Button from '../components/button';
import TitleBlock from '../components/title-block';
import Testimonials from '../components/testimonials';
import FirstMeeting from '../components/first-meeting';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { StaticImage } from 'gatsby-plugin-image';
import LatestCase from '../components/latest-case';
import RichContent from '../components/rich-content';

export default function Solutions(props) {
	let { data } = props;
	let { sanitySolutionsPage } = data;
	let { title, solutions, seo } = sanitySolutionsPage;

	let renderSolutions = [];
	for (let solution of solutions ?? []) {
		let { _id, title, subtitle, _rawDescription, image, link } = solution;

		let renderLink;
		if (link && link.url) {
			renderLink = (
				<div className="mt-6">
					<Button as="link" to={link.url}>
						{link.text ?? 'Discover'}
					</Button>
				</div>
			);
		}

		let renderInfo = (
			<div className="row-start-2 md:row-start-auto">
				<h2 className="text-2xl text-primary">{title}</h2>
				<h3 className="uppercase text-tertiary">{subtitle}</h3>
				<div className="max-w-lg mt-6">
					<RichContent blocks={_rawDescription} />
				</div>
				{renderLink}
			</div>
		);
		let renderImage = <img src={image.asset.url+"?fit=max&h=450"} className="object-cover w-full h-full md:max-h-80" />;

		if (renderSolutions.length % 2 === 0) {
			renderSolutions.push(
				<div key={_id} className="grid gap-6 md:gap-10 xl:gap-16 md:grid-cols-2 items-y-center">
					{renderInfo}
					{renderImage}
				</div>,
			);
		} else {
			renderSolutions.push(
				<div key={_id} className="grid gap-6 md:gap-10 xl:gap-16 md:grid-cols-2 items-y-center">
					{renderImage}
					{renderInfo}
				</div>,
			);
		}
	}

	return (
		<Layout seo={seo}>
			<Hero>
				<div className="grid gap-12 xl:grid-cols-2">
					<div className="grid grid-rows-[max-content,max-content] self-y-center gap-8 xl:max-w-3xl">
						<h1 className="text-4xl md:text-5xl 2xl:text-6xl self-y-center">
							<TitleBlock title={title} />
						</h1>

						<Link
							to="#solutions"
							className="flex items-center justify-center mt-12 xl:justify-start xl:mt-0 xl:w-max text-primary"
						>
							<span className="text-sm uppercase md:text-base 2xl:text-lg">Discover</span>
							<ChevronDownIcon className="w-6 h-6 ml-2 transition-transform duration-300 transform text-primary" />
						</Link>
					</div>

					<div className="relative w-full rounded-full lg:h-full lg:w-auto lg:max-w-none">
						<StaticImage src="../images/hero-solutions.svg" alt="Building" />
					</div>
				</div>
			</Hero>

			<Section id="solutions">
				<div className="grid max-w-screen-xl gap-6 mx-auto items-y-start md:gap-10 xl:gap-16">{renderSolutions}</div>
			</Section>

			<Section>
				<LatestCase />
			</Section>

			<Testimonials />

			<FirstMeeting />
		</Layout>
	);
}

export const query = graphql`
	query($language: String) {
		sanitySolutionsPage(lang: { eq: $language }) {
			title {
				children {
					_key
					text
					marks
				}
			}
			solutions {
				_id
				title
				subtitle
				image {
					asset {
						url
					}
				}
				link {
					url
					text
				}
				_rawDescription(resolveReferences: { maxDepth: 5 })
			}
			seo {
				description
				image {
					asset {
						url
					}
				}
			}
		}
	}
`;
