import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import usePageContext from '../hooks/use-page-context';
import Button from './button';

export default function LatestCase() {
	let { language } = usePageContext();
	let data = useStaticQuery(query);
	let { allSanityBrainjarReference } = data;
	let { nodes: cases } = allSanityBrainjarReference;

	if (!cases) return null;

	let { _rawLanguages: languages, slug, image } = cases[0];
	let { title, description } = languages[language];

	return (
		<section className="grid items-y-center relative min-h-[24rem]">
			<div className="z-10 flex flex-col px-6 py-12 mx-auto text-center sm:px-12 max-w-7xl">
				<h2 className="text-2xl text-primary">{title}</h2>
				<p className="mt-6 text-lg">{description}</p>
				<span className="mt-6">
					<Button as="link" to={`/${language}/references/${slug?.current}`}>
						Read more
					</Button>
				</span>
			</div>
			<img src={image?.asset?.url} className="absolute inset-0 object-cover w-full h-full filter brightness-[0.45]" />
		</section>
	);
}

const query = graphql`
	query LatestReference {
		allSanityBrainjarReference(sort: { fields: _createdAt, order: DESC }, limit: 1) {
			nodes {
				id
				company
				slug {
					current
				}
				_rawLanguages(resolveReferences: { maxDepth: 5 })
				image {
					asset {
						url
					}
				}
				_createdAt
				_updatedAt
			}
		}
	}
`;
